import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 1rem;
  }

  h2,
  h3 {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 576px) {
    h1 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    h2,
    h3 {
      margin-bottom: 2rem;
    }
  }
`;
