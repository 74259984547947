import React from 'react';
import Section from '../components/Section';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledContainer } from '../layouts/styles';
import Metadata from '../components/Metadata';

const StyledHeadingImage = styled.div`
  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  gap: 3rem;

  p {
    font-size: 1.5rem;
  }
`;

const StyledHeadingContainer = styled.div`
  max-width: 80%;
`;

function Methoden() {
  return (
    <>
      <Metadata title='Methoden' />
      <StyledContainer>
        <StyledHeadingContainer>
          <h1>Methoden und Ansätze</h1>
        </StyledHeadingContainer>
        <Section>
          <StyledHeadingImage>
            <div>
              <p>
                Wir nutzen Methoden, die laut dem aktuellen Forschungsstand als
                wirksam eingestuft wurden und aus dem Gebiet der
                verhaltenstherapeutischen Ansätze stammen.
              </p>
            </div>
            <StaticImage
              src='../images/Methoden.png'
              alt='Wegweiser'
            ></StaticImage>
          </StyledHeadingImage>
        </Section>
        <Section>
          <p>
            Die individuellen Bedürfnissen unserer Klient:innen stehen bei der
            Auswahl und der Anwendung einzelner Methoden und Therapiestrategien
            im Fokus. Denn, auch wenn jede:r unserer Klient:innen eine
            diagnostizierte Autismus-Spektrum-Störung hat, sind die
            Einschränkungen, die Persönlichkeit, die Bedürfnisse und das System,
            in welchem die Person lebt, unterschiedlich. Konkret bedeutet das,
            dass es für jede Fragestellung oder jedes Ziel mehrere mögliche
            Vorgehensweisen gibt.
          </p>
          <p>
            In der Therapie nehmen wir stets eine systemische Perspektive ein:
            Wir betrachten das Kind oder die/den Jugendliche/n und sein/ihr
            jeweiliges (Kommunikations-)Verhalten im Kontext der vorhandenen
            sozialen Beziehungen.
          </p>
          <p>
            Die Spezialisierung auf unterschiedliche Bedürfnisse und
            Förderbedarfe spiegelt sich in unserem Raumkonzept, den
            Qualifikationen des Therapeutenteams und auch der angewandten
            Methoden wider.
          </p>
          <p>
            In unserer Arbeit fließen abhängig vom Alter und Entwicklungsstand
            folgende methodische Elemente und Angebote ein:
          </p>
          <ul>
            <li>
              Verhaltenstherapeutische Methoden:
              <ul>
                <li>
                  Applied Behavior Analysis/ Verbal Behavior (
                  <a href='/abavb' target='_blank' rel='noreferrer'>
                    ABA/VB
                  </a>
                  )
                </li>
                <li>
                  Akzeptanz- und Commitment-Therapie (
                  <a href='/act' target='_blank' rel='noreferrer'>
                    ACT
                  </a>
                  )
                </li>
                <li>
                  Dialektisch-Behaviorale Therapie (
                  <a href='/dbt' target='_blank' rel='noreferrer'>
                    DBT
                  </a>
                  )
                </li>
              </ul>
            </li>
            <li>
              Systemische Ansätze:
              <ul>
                <li>
                  <a href='/einzelcoaching' target='_blank' rel='noreferrer'>
                    Einzelcoaching
                  </a>
                </li>
                <li>
                  <a href='/elterncoaching' target='_blank' rel='noreferrer'>
                    Elterncoaching
                  </a>
                </li>
                <li>
                  <a href='/narrativetherapie' target='_blank' rel='noreferrer'>
                    narrative Therapie
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Kommunikationsförderung:
              <ul>
                <li>
                  individuelle Beratungen über alternative Formen der
                  Kommunikation
                </li>
                <li>
                  Einüben alternativer Formen der Kommunikation, z.B. Gebärden,
                  elektronische oder symbolbasierte-Kommunikationssysteme
                </li>
              </ul>
            </li>
            <li>
              <a href='/embodiment' target='_blank' rel='noreferrer'>
                Embodiment in der therapeutischen Kommunikation
              </a>
            </li>
            <li>Spieltherapeutische Zugänge</li>
            <li>Strukturierende Ansätze</li>
            <li>Fachberatung und Psychoedukation</li>
            <li>Elternabende und Impulsvorträge</li>
          </ul>
        </Section>

        <Section>
          <section>
            <h4 style={{ fontSize: '22px', marginBottom: '1rem' }}>
              Friedrich-Wilhelm-Straße
            </h4>
            <p>
              Der Standort auf der Friedrich-Wilhelm-Straße bietet Förderplätze
              für Kinder im KiTa- und Grundschulalter, die einen vergleichsweise
              hohen Förder- und Unterstützungsbedarf haben. Die Kinder lernen
              hier gemeinsam mit anderen Kindern und teilen die verschiedenen
              Räume, die ihnen zur Verfügung stehen. So entstehen natürliche
              Begegnungen und auch Übungsanlässe aus den Bereichen Kommunikation
              und soziale Interaktion. Unsere Räume bieten Bewegungsanlässe,
              Möglichkeiten des Rückzuges sowie auch Bereiche, um fokussiert
              lernen zu können.
            </p>
          </section>

          <section>
            <h4 style={{ fontSize: '22px', marginBottom: '1rem' }}>
              Tonhallenstraße
            </h4>
            <p>
              Am Standort an der Tonhallenstraße finden Kinder, Jugendliche und
              junge Volljährige einen Therapieplatz, deren
              Teilhabeeinschränkungen sich vor allem in der sozialen Interaktion
              zeigen und eine weniger zeitintensive Therapie benötigen, um
              Beeinträchtigungen zu kompensieren. Einzelräume bieten hier die
              Möglichkeit vor allem gesprächsbasierte Therapieeinheiten in einem
              vertraulichen Umfeld umzusetzen.
            </p>
          </section>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Methoden;
